<template>
	<div>
		<card-page v-if="!country">
			<template v-slot:page>
				<div class="m-4 px-4 pb-4 payment-form">
					<valid-countries></valid-countries>
				</div>
			</template>
		</card-page>
		<template v-else>
			<pay-pal v-if="false /*&& payment.currency === 'USD'*/">
				<valid-countries></valid-countries>
			</pay-pal>
			<template v-else>
				<card-flutterwave v-if="!cellulantSupported">
					<valid-countries></valid-countries>
				</card-flutterwave>
				<ting-cellulant v-if="cellulantSupported">
					<valid-countries></valid-countries>
				</ting-cellulant>
			</template>
			<card-dlocal v-if="false"></card-dlocal>
			<card-direct v-if="false"></card-direct>
		</template>
	</div>
</template>

<script>
import countries from '@odusanya/african-countries'
import {mapState, mapActions, mapGetters} from 'vuex'
import CardFlutterwave from "../components/CardFlutterwave";
import CardDirect from "../components/CardDirect";
import CardDlocal from "../components/CardDlocal";
import PayPal from "../components/PayPal";
import ValidCountries from "../components/ValidCountries";

const {listCountries} = countries;
import CardPage from "../components/CardPage";
import lo from 'lodash';
import TingCellulant from "../components/TingCellulant";
import constants from "../constants";

export default {
	name: "CardView",
	components: {TingCellulant, CardPage, ValidCountries, PayPal, CardDlocal, CardDirect, CardFlutterwave},
	computed: {
		...mapState(['card_driver', 'country']),
		...mapGetters({
			payment: constants.GET_PAYMENT,
		}),
		driver() {
			if (this.card_driver.driver)
				return this.card_driver.driver;

			return null;
		},
		africa() {
			return lo.map(listCountries(), (country) => {
				return country.Code;
			});
		},
		cellulantAfrica() {
			return lo.map(lo.filter(listCountries(), (country) => {
				return lo.includes(constants.CELLULANT_COUNTRIES, country.Code);
			}), (country) => {
				return country.Code;
			});
		},
		african() {
			if (this.country) {
				return lo.includes(this.africa, this.country);
			}

			return false;
		},
		cellulantSupported() {
			if (this.country) {
				return lo.includes(this.cellulantAfrica, this.country);
			}

			return false;
		}
	},
	methods: {
		...mapActions({
			setFeesType: constants.SET_FEES_TYPE
		})
	},
	watch: {
		country() {
			// if (this.african) {
			//	this.setFeesType(constants.DEFAULT_FEES_TYPE);
			// } else {
			// 	this.setFeesType(constants.PAYPAL);
			// }
			 this.setFeesType(constants.DEFAULT_FEES_TYPE);
		}
	}
}
</script>

<style scoped>

</style>
